export { asignarMenus }

function asignarMenus(arreglo){
    let menu = '';
    let diccionario = {};

    if(arreglo.length > 0){
        for(var i=0; i<arreglo.length; i++){
            menu = arreglo[i].url
        
            if(!rutas.includes(menu)){
                rutas.push(menu);
                
                if(menu.includes("Programador") && programadorM === false){
                    programadorM = true
                    menus.push("Programador")
                }
                if(menu.includes("Usuarios") && usuarioM===false){
                    usuarioM = true
                    menus.push("Usuarios")
                }
                if(menu.includes("RFID") && rfidM === false){
                    rfidM = true
                    menus.push("RFID")
                }
                if(menu.includes("Forklift") && forkliftM === false){
                    forkliftM = true
                    menus.push("Forklift")
                }
                if(menu.includes("Trazabilidad") && trazabilidadM === false){
                    trazabilidadM = true
                    menus.push("Trazabilidad")
                }
                if(menu.includes("Reportes") && reportesM === false){
                    reportesM = true
                    menus.push("Reportes")
                }
                if(menu.includes("Inventario") && inventarioM===false){
                    inventarioM = true
                    menus.push("Inventario")
                }
                if(menu.includes("Reporte") && reporteM===false){
                    reporteM = true
                    menus.push("Reporte")
                }
                if(menu.includes("Rastreo") && rastreoM===false){
                    rastreoM = true
                    menus.push("Rastreo")
                }
            }
        }
        
        diccionario['rutas'] = rutas
        diccionario['menus'] = menus

        // console.log(diccionario)
        
        return diccionario;
    }
    else{
        diccionario['rutas'] = []
        diccionario['menus'] = []

        // console.log(diccionario)
        
        return diccionario;

    }
}
var rutas = [];
var menus = [];
var programadorM = false;
var usuarioM = false;
var rfidM = false;
var forkliftM = false;
var trazabilidadM = false;
var reportesM = false;
var inventarioM = false;
var reporteM = false;
var rastreoM = false;
