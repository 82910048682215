import React, { Component } from "react";
import axios from "axios";
import * as urlsC from "../../constants/urls";
import { autorizacion } from "../../constants/urls";
import Seguridad from '../dashboard/seguridad/Render';
import Footer from "../dashboard/Footer";
import Browser from '../dashboard/Browser';
import { asignarMenus } from "../dashboard/Funciones";


var usuario="";
var tipoUsuario = "";
var perfil = "";
var diccionarioEx = {}
var profileEx = "";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: localStorage.getItem('token') ? true : false,
      username: '',
      diccionario: {'rutas':[],'menus':[]},
      rutas:[],
      tipoUsuario: '',
    };
  }


  componentDidMount(){
    let self = this;
    let url = "";
    
    if(this.state.loggedIn){
      url = String.prototype.concat(urlsC.usuarioActualExterno,localStorage.getItem('token'),'/')

      axios.get(url, autorizacion).then((response) =>{
        self.setState({
          username : response.data[0],
          tipoUsuario : response.data[1]
        })

        usuario = response.data[0]
        tipoUsuario = response.data[1]

        this.identificarUsuario(response.data[0])
        this.identicarPerfil(response.data[0])
        

        if(localStorage.getItem('modo') === null){
          localStorage.setItem('modo', 'actualizado');
        }
        
        if(localStorage.getItem('modoNocturno') === null){
          localStorage.setItem('modoNocturno', 'inactivo');
        }
        
        if(localStorage.getItem('menu') === null){
          localStorage.setItem('menu', 'principal');
        }

      });
    }
    else{
      form =  <Seguridad />;
    }
  };


  resetState(){
  }


  identificarUsuario(usuario){
    let self = this
    let url = String.prototype.concat(urlsC.obtenerPermisosUsuario,usuario,'/')
    let aux = {}
    
    axios.get(url,autorizacion).then(function (result) {
      try {
        aux = asignarMenus(result.data)
        self.setState({ diccionario : aux})
        diccionarioEx = aux;
      }
      catch(error){
        console.log('Error - Permisos')
      }
    });
  }

    
  identicarPerfil(usuario){
    let self = this;    
    //dominio del correo
    let dominio = usuario.substring(usuario.indexOf('@')+1,usuario.length)

    //perfil por correo
    if(dominio === "xicoatl.com"){
      perfil = "administrador"
    }
    else{
      perfil = "usuario"
    }
  
    console.log(usuario,'|',tipoUsuario,'|',perfil, '|', dominio)
    
    
    //obtener foto usuario
    var urlP = String.prototype.concat(urlsC.UploadProfilePictureFilter,'?username=',usuario);
    
    axios.get(urlP, autorizacion).then((result) => {
      //si subio previamente una foto
      try{
        if(!(result.data.results[0]['fileUrl'].includes('8000'))){
          self.setState({
            profile:(result.data.results[0]['fileUrl']).replace('http','https'),
          })
          profileEx = (result.data.results[0]['fileUrl']).replace('http','https');
        }
        else{
          self.setState({
            profile:result.data.results[0]['fileUrl'],
          })
          profileEx = result.data.results[0]['fileUrl'];
        }
      }
      //si nunca ha subido una foto
      catch{
        if(!(urlsC.URL.includes('8000'))){
          self.setState({
            profile: (urlsC.URL + "/media/images/usuarios/Perro.PNG").replace('http','https'),
          })
          profileEx = (urlsC.URL + "/media/images/usuarios/Perro.PNG").replace('http','https');
        }
        else{
          self.setState({
            profile: (urlsC.URL + "/media/images/usuarios/Perro.PNG"),
          })
          profileEx = (urlsC.URL + "/media/images/usuarios/Perro.PNG");
        }
      }
    })

    // eslint-disable-next-line
    let accion = {
      "username": usuario,
      "codigo": 'lg',
      "accion": 'n',
      "registro": 'Login'
    }

    //this.guardarAccion(accion);
  }


  guardarAccion(data){
    let url = String.prototype.concat(urlsC.URL,'/usuarios/LogUsuarioList/')
    axios.post(url, data, autorizacion).then(response => response.data);
  }

 
  render(){
    if(tiempo < 50){
      tiempo = tiempo + 1;
    }
    else{
      tiempo = 0;
    }

    return (
      <div className="App">
        {!this.state.loggedIn &&
          form
        }
        {this.state.loggedIn &&
          <Browser
            loggedIn={this.state.loggedIn}
            username={this.state.username}
            rutas={this.state.diccionario['rutas']}
            menus={this.state.diccionario['menus']}
            tiempo={tiempo}
            tipoUsuario={this.state.tipoUsuario}
            profile={this.state.profile}
          />
        }
        <Footer
          loggedIn = {this.state.loggedIn}
        />
      </div>
   );
  }
}
var form;
var tiempo = 0;
export { usuario, tipoUsuario, perfil, diccionarioEx, profileEx };
export default App;