import React, { Component } from "react";
import { IconoTelefono } from "./Style";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estilo_base : "footerIn",
    };
  }
  
  componentDidMount(){
    let self = this;
    if(this.props.loggedIn){
      self.setState({estilo_base:"footerOut"})
    }
  }

  render() {
    return (
        <footer className= {this.state.estilo_base}>
          <a href="https://www.xicoatl.com/" className="link">Xicoatl © </a> | 2024 | <IconoTelefono style={{color:"#7c09c8"}} /> <a href="tel:+55 0000 0000" className="link">55 0000 0000</a> - <a href="tel:+55 0000 0000" className="link"> 55 0000 0000</a>
        </footer>
    );
  }
}

export default Footer;