import React, { Component } from 'react'
import axios from "axios";
import { Row, Col, Button } from "reactstrap";
import { tokenAuth, generarAutorizacion, usuarioActual, autorizacion } from "../../../constants/urls";
// import LogoEmpresa from "../../../media/images/empresa/logo.png";
import MicrosoftIn from './MicrosoftIn';
import { Spinner } from 'reactstrap';


class DjangoIn extends Component {

  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: '',
      status: false,
      widthLogin: 0,
    }
  }
  
  componentDidMount(){
    let self = this;
    
    self.setState({ widthLogin : window.screen.width/3 })
    localStorage.setItem('menu','principal')
    
  }

  iniciarSesion = (e, data) =>{
    let self = this;
    e.preventDefault();
    
    self.setState({status : true })

    fetch(tokenAuth, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data),
    }).then(res => res.json()).then(json => {
      let url = String.prototype.concat(generarAutorizacion,data.username,'/',data.password,'/I/')
      axios.get(url,autorizacion).then(function (result) {
        //console.log(url,result.data)
        try{
          let config = {
            headers: {
              'Authorization': `Basic ${result.data}`
            }
          }
          
          axios.get(usuarioActual, config).then((response) =>{
            localStorage.setItem('token', result.data);
            window.location.reload();
          }).catch(error  => {
            alert('¡Credenciales incorrectas!')
            self.setState({status : false})
            }
          );
        }
        catch(error){
          console.log(error)
        }
      });
    }).catch(error => {
      alert('¡Credenciales incorrectas!')
      // self.setState({status : false})
      }
    )
  }
  
  keyPress(e){ 
    if(e.keyCode === 13){
      this.iniciarSesion(e, this.state)
    }
  }

  render(){
    // console.log(this.state)
    return(
      <div className='divLogin' style={{ width: this.state.widthLogin}}>
        <Row>
          <Col className="centrarPaginacion">
            {/* <img
              alt={"Descripcion"}
              src={LogoEmpresa}
              width="280"
              // style={{ marginTop: "50px", marginBottom: "0px" }}
            /> */}
          </Col>
        </Row>
        <div>
        {!this.state.status ?
            <Row style={{marginTop:"10px", marginBottom:"10px", marginLeft: "10px", marginRight: "10px"}}>
              <div>
                <form>
                  <div className="estiloLogin">Correo electrónico</div>
                  <input type="user" className="form-control" placeholder="Ingrese su correo"
                    onChange = {event =>{ 
                      const {value} = event.target;
                      this.setState({username:value});
                    }}
                  />
                  <br />
                  <div className="estiloLogin">Contraseña</div>
                  <input type="password" autoComplete = "" className="form-control" placeholder="Ingrese su contraseña"
                    onChange = {event =>{ 
                      const {value} = event.target;
                      this.setState({password:value});
                    }}
                    onKeyDown={(e)=>{this.keyPress(e)}}
                  />
                  <div style={{color:"#888", textAlign:"right", fontWeight:"Bold", fontSize:"16px"}}>¿Olvidó su contraseña?</div>
                </form>
                <br />
                <Button
                  className="estiloPrincipal"
                  size="md"
                  block
                  onClick={ e => this.iniciarSesion(e,this.state) }
                >
                  <div style={{color:"white", textAlign:"center", fontWeight:"Bold", fontSize:"16px"}}>Ingresar</div>
                </Button>
              </div>
            </Row>
          :
            <Spinner type="grow" className="colorBase" style={{width:'50px', height:'50px'}}/>
        }
        </div>
        <h6 className="colorPrincipal">ó</h6>
        <Row>
          <div className="centrarPaginacion" style={{marginBottom:"30px"}}>
            <MicrosoftIn />
          </div>  
        </Row>
      </div>
    );
  }
}
export default DjangoIn;