import React, { useState } from "react";
import axios from "axios";
import MicrosoftLogin from "react-microsoft-login";
import * as urlsC from "../../../constants/urls";
import { Button } from "reactstrap";
import { SiWindows } from 'react-icons/si';

var loggedInMicrosoft = false;

const MicrosoftIn = (props) => {
  const [msalInstance, onMsalInstanceChange] = useState();

  const loginHandler = (err, data, msal) => {

    if(data !== undefined){
      //console.log(data.account.username,data.accessToken.replaceAll("/",""));
      if(data.account.username){
        loggedInMicrosoft = true;
        let url = String.prototype.concat(urlsC.generarAutorizacion,data.account.username,'/',data.accessToken.replaceAll("/",""),'/M/')
  
        axios.get(url,urlsC.autorizacion).then(function (result) {
          try{
            localStorage.setItem('token', result.data);
            window.location.reload();
          }
          catch(error){
            console.log(error)
          }
        });
      }
    }
    else{
      alert('¡Inicio de sesión abortado!')
    }
    
    if (!err && data) {
      onMsalInstanceChange(msal);
    }
  };

  const logoutHandler = () => {
    msalInstance.logout();
  };


  return loggedInMicrosoft ? (
  <Button
      size="lg"
      className="estiloAccion"
      onClick={logoutHandler}
  >
      Cerrar sesión
  </Button>
  )
  :
  (
    <MicrosoftLogin
      clientId={'6910ff36-19d9-4f8d-a1c4-536db0093c1a'}
      authCallback={loginHandler}
      className={'botonLogin'}
    >
      <Button
          size="md"
          block
          className="estiloPrincipal"
      >
        <div style={{color:"white", textAlign:"center", fontWeight:"Bold", fontSize:"16px"}}>Acceder con <SiWindows /></div>
      </Button>
    </MicrosoftLogin>
  );
};
export default MicrosoftIn;