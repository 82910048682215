import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Redirect  } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import RegistroExterno from './seguridad/registroExterno/Render';
import { colorFondo, colorTexto } from "./Style";


//Dashboard
const Home = lazy(() => import('./Home'))
const Sidebar = lazy(() => import ('./Sidebar'))
const Herramientas = lazy(() => import ('./Herramientas'))
const MenuRFID = lazy(() => import ('../modulos/rfid/Sidebar'))
const MenuRastreo = lazy(() => import ('../modulos/rastreo/Sidebar'))

const Dispositivos = lazy(() => import ('../modulos/rastreo/rastreo/dispositivos/Render'))
const UltimosRegistros = lazy(() => import ('../modulos/rastreo/rastreo/ultimosRegistros/Render'))
const MapaU = lazy(() => import ('../modulos/rastreo/rastreo/ultimosRegistros/mapa/Mapa'))
const Historial = lazy(() => import ('../modulos/rastreo/rastreo/historial/Render'))
const MapaHistorial = lazy(() => import ('../modulos/rastreo/rastreo/historial/mapa/Mapa'))


const HomeRFID = lazy(() => import('../modulos/rfid/Home'))
const HomeRastreo = lazy(() => import('../modulos/rastreo/Home'))
// const Password = lazy(() => import ('./Password'))
const Perfil = lazy(() => import ('./Perfil'))
const CerrarSesion = lazy(() => import ('./CerrarSesion'))
const Modo = lazy(() => import ('./modo/Modo'))

//MaquinasVirtuales
const MaquinasVirtuales = lazy(() => import ('../modulos/operaciones/maquinasVirtuales/Render'))
const Monitor = lazy(() => import ('../modulos/operaciones/monitoreo/Render'))


//Usuarios
const Usuarios = lazy(() => import ('../modulos/usuarios/usuario/Render'))

//RFID
const RFIDProduct = lazy(() => import ('../modulos/rfid/catalogos/rfidProduct/Render'))
const ForkliftUser = lazy(() => import ('../modulos/rfid/catalogos/montacarguista/Render'))
const MapRFID = lazy(() => import ('../modulos/rfid/catalogos/antena/Render'))
const ForkliftReader = lazy(() => import ('../modulos/rfid/catalogos/reader/Render'))
const Forklifts = lazy(() => import ('../modulos/rfid/catalogos/montacargas/Render'))


//Reportes
const RMontacargas = lazy(() => import ('../modulos/rfid/reportes/montacargas/Render'))
const EnAlmacen = lazy(() => import ('../modulos/rfid/reportes/enAlmacen/Render'))
const FueraAlmacen = lazy(() => import ('../modulos/rfid/reportes/fueraAlmacen/Render'))
const Inventario = lazy(() => import ('../modulos/rfid/reportes/inventario/Render'))
const Ubicacion = lazy(() => import ('../modulos/rfid/reportes/ubicacion/Render'))
const Trazabilidad = lazy(() => import ('../modulos/rfid/reportes/trazabilidad/Render'))
const Comparativa = lazy(() => import ('../modulos/rfid/reportes/comparativa/Render'))
const ComparativaGrafica = lazy(() => import ('../modulos/rfid/reportes/comparativaGrafica/Render'))
const SubirExcel = lazy(() => import ('../modulos/rfid/reportes/excel/Render'))
const GraficaBobinas = lazy(() => import ('../modulos/rfid/home/Bobinas'))



function Browser(props) {
  const logeado = (
    <div style={{ backgroundColor:colorFondo, color:colorTexto }}>
      <BrowserRouter>
        <Route exact path="/">
          <Redirect to="/Home" />
        </Route>

        <Suspense
          fallback={
            <div className="centrarCircular">
              <Spinner type="grow" className="estiloCarga"/>
            </div>
          }
        >
          {/* <Menu
            menus={props.menus}
            rutas={props.rutas}
            tipoUsuario={props.tipoUsuario}
            profile={props.profile}
          /> */}

          {localStorage.getItem('menu') === 'principal' &&
            <Sidebar />
          }
          {localStorage.getItem('menu') === 'rfid' &&
            <MenuRFID />
          }
          {localStorage.getItem('menu') === 'rastreo' &&
            <MenuRastreo />
          }
          <Herramientas />
          {/* Principal */}              
          <Route path='/Home' component={Home} />
          <Route path='/Perfil' component={Perfil} />
          {/* <Route path='/Seguridad' component={Password} /> */}
          <Route path='/Modo' component={Modo} />
          <Route path='/cerrarsesion' component={CerrarSesion} />

          <Route path='/RFID/Inicio' component={HomeRFID} />
          <Route path='/Rastreo/Inicio' component={HomeRastreo} />


          {props.menus.includes('RFID') &&
            <>
              {props.rutas.includes('/RFID/Administrador') &&
                <>
                  <Route path='/RFID/Catalogos/ProductoRFID' component={RFIDProduct} />
                  <Route path='/RFID/Catalogos/Antena' component={MapRFID} />
                </>
              }
              {props.rutas.includes('/RFID/Usuario') &&
                <>
                  <Route path='/RFID/Catalogos/ProductoRFID' component={RFIDProduct} />
                  <Route path='/RFID/Catalogos/Antena' component={MapRFID} />
                </>
              }
            </>
          }

          {props.menus.includes('Forklift') &&
            <>
              {props.rutas.includes('/Forklift/Administrador') &&
                <>
                  <Route path='/RFID/Catalogos/Montacarguista' component={ForkliftUser} />
                  <Route path='/RFID/Catalogos/Reader' component={ForkliftReader} />
                  <Route path='/RFID/Catalogos/Montacargas' component={Forklifts} />
                  <Route path='/RFID/Reportes/EnAlmacen' component={EnAlmacen} />
                  <Route path='/RFID/Reportes/FueraAlmacen' component={FueraAlmacen} />
                  <Route path='/RFID/Reportes/Montacargas' component={RMontacargas} />
                  <Route path='/RFID/Reportes/Inventario' component={Inventario} />
                  <Route path='/RFID/Reportes/Comparativa' component={Comparativa} />
                  <Route path='/RFID/Reportes/ComparativaGrafica' component={ComparativaGrafica} />
                  <Route path='/RFID/Reportes/SubirExcel' component={SubirExcel} />
                  <Route path='/RFID/Reportes/Ubicacion' component={Ubicacion} />
                  <Route path='/RFID/Reportes/GraficaBobinas' component={GraficaBobinas} />
                  <Route path="/RFID/Reportes/Trazabilidad/:id" component={Trazabilidad} />
                </>
              }
              {props.rutas.includes('/Forklift/Usuario') &&
                <>
                  <Route path='/RFID/Catalogos/Montacarguista' component={ForkliftUser} />
                  <Route path='/RFID/Catalogos/Reader' component={ForkliftReader} />
                  <Route path='/RFID/Catalogos/Montacargas' component={Forklifts} />
                  <Route path='/RFID/Reportes/EnAlmacen' component={EnAlmacen} />
                  <Route path='/RFID/Reportes/FueraAlmacen' component={FueraAlmacen} />
                  <Route path='/RFID/Reportes/Inventario' component={Inventario} />
                  <Route path='/RFID/Reportes/Comparativa' component={Comparativa} />
                  <Route path='/RFID/Reportes/ComparativaGrafica' component={ComparativaGrafica} />
                  <Route path='/RFID/Reportes/GraficaBobinas' component={GraficaBobinas} />
                  <Route path='/RFID/Reportes/SubirExcel' component={SubirExcel} />
                  <Route path='/RFID/Reportes/Montacargas' component={RMontacargas} />
                  <Route path='/RFID/Reportes/Ubicacion' component={Ubicacion} />
                  <Route path="/RFID/Reportes/Trazabilidad/:id" component={Trazabilidad} />
                </>
              }
            </>
          }

          {props.menus.includes('MV') &&
            <>
              {props.rutas.includes('/MV/Usuario') &&
                <>
                  <Route path='/MaquinasVirtuales/Listado' component={MaquinasVirtuales} />
                  <Route path='/Monitor/:ip' component={Monitor} />
                </>
              }
              {props.rutas.includes('/MV/Administrador') &&
                <>    
                  <Route path='/MaquinasVirtuales/Listado' component={MaquinasVirtuales} />
                  <Route path='/Monitor/:ip' component={Monitor} />
                </>
              }
            </>
          }

          {props.menus.includes('Rastreo') &&
            <>
              {props.rutas.includes('/Rastreo/Administrador') &&
                <>
                  <Route path='/Rastreo/Rastreo/Dispositivos' component={Dispositivos} />
                  <Route path='/Rastreo/Rastreo/UltimosRegistros' component={UltimosRegistros} />
                  <Route path='/Rastreo/Rastreo/DetectarDireccion/:latitud/:longitud/:id' component={MapaU} />
                  <Route path='/Rastreo/Rastreo/Historial' component={Historial} />
                  <Route path='/Rastreo/Rastreo/MapaHistorial/:imei/:tipo' component={MapaHistorial} />
                </>
              }
              {props.rutas.includes('/Rastreo/Usuario') &&
                <>
                  <Route path='/Rastreo/Rastreo/Dispositivos' component={Dispositivos} />
                  <Route path='/Rastreo/Rastreo/UltimosRegistros' component={UltimosRegistros} />
                  <Route path='/Rastreo/Rastreo/DetectarDireccion/:latitud/:longitud/:id' component={MapaU} />
                  <Route path='/Rastreo/Rastreo/Historial' component={Historial} />
                  <Route path='/Rastreo/Rastreo/MapaHistorial/:imei/:tipo' component={MapaHistorial} />
                </>
              }
            
            </>
          }
          

          {props.menus.includes('Usuarios') &&
            <>
              {props.rutas.includes('/Usuarios/Administrador') &&
                <>
                  <Route path='/Usuarios/Permisos' component={Usuarios} />
                </>
              }
            </>
          }
        </Suspense>
      </BrowserRouter>
    </div>
  );

  const programador = (
    <div style={{ backgroundColor:colorFondo, color:colorTexto }}>
      <BrowserRouter>
        <Route exact path="/">
          <Redirect to="/Home" />
        </Route>

        <Suspense
          fallback={
            <div className="centrarCircular">
              <Spinner type="grow" className="estiloCarga"/>
            </div>
          }
        >
          {/* <Menu
            menus={props.menus}
            rutas={props.rutas}
            tipoUsuario={props.tipoUsuario}
            profile={props.profile}
          /> */}

          {/* Principal */}              
          <Route path='/Home' component={Home} />
          <Route path='/Perfil' component={Perfil} />
          {/* <Route path='/Seguridad' component={Password} /> */}
          <Route path='/Modo' component={Modo} />
          <Route path='/cerrarsesion' component={CerrarSesion} />
          <Route path='/RegistroExterno' component={RegistroExterno} />

          
            
          {props.menus.includes('MV') &&
            <>
              <Route path='/MaquinasVirtuales/Listado' component={MaquinasVirtuales} />
              <Route path='/Monitor/:ip' component={Monitor} />
            </>
          }  

          {props.menus.includes('Rastreo') &&
            <>
              {props.rutas.includes('/Rastreo/Administrador') &&
                <>
                  <Route path='/Rastreo/Rastreo/Dispositivos' component={Dispositivos} />
                  <Route path='/Rastreo/Rastreo/UltimosRegistros' component={UltimosRegistros} />
                  <Route path='/Rastreo/Rastreo/DetectarDireccion/:latitud/:longitud/:id' component={MapaU} />
                  <Route path='/Rastreo/Rastreo/Historial' component={Historial} />
                  <Route path='/Rastreo/Rastreo/MapaHistorial/:imei/:tipo' component={MapaHistorial} />
                </>
              }
              {props.rutas.includes('/Rastreo/Usuario') &&
                <>
                  <Route path='/Rastreo/Rastreo/Dispositivos' component={Dispositivos} />
                  <Route path='/Rastreo/Rastreo/UltimosRegistros' component={UltimosRegistros} />
                  <Route path='/Rastreo/Rastreo/DetectarDireccion/:latitud/:longitud/:id' component={MapaU} />
                  <Route path='/Rastreo/Rastreo/Historial' component={Historial} />
                  <Route path='/Rastreo/Rastreo/MapaHistorial/:imei/:tipo' component={MapaHistorial} />
                </>
              }
            
            </>
          } 
            
          {props.menus.includes('Usuarios') &&
            <>
              <Route path='/Usuarios/Permisos' component={Usuarios} />
            </>
          }
        </Suspense>
      </BrowserRouter>
    </div>
  );

  switch(props.loggedIn){
    case true:
      if(props.username === 'gerardo.mendoza@banuup.com'){
        return(
          <div>
            {props.rutas.length > 0 && programador}
          </div>
        );
      }
      else
      {
        if(props.username === undefined){
          return(
            <div>
              <CerrarSesion />
            </div>
          );
        }
        else{
          return(
            <div>
              {props.rutas.length > 0 ?
                logeado
                :
                <div>
                  {props.tiempo > 10 && 
                    <RegistroExterno
                      username={props.username}
                    />
                  }
                </div> 
              }
            </div>
          );
        }
      }
    case false:
      return(
        <div>
        </div>
      );
    
    default:
  }
}
export default Browser;