import React, { Component } from 'react'
import Create from "./Create";
import { Button } from "reactstrap";
import "../../../../css/index.css";
import FondoEmpresa from "../../../../media/images/Inicio.jpg";

class Render extends Component {
  constructor(props){
    super(props);
    this.state={};
  }

  componentDidMount(){
  }

  cerrarSesion() {
    localStorage.removeItem('token');
    window.location.replace("/");
  }

  render(){
    return( 
      <div key='signup' className="estilo_base">
        {/*eslint-disable-next-line*/}
        <img className="estilo_imagen" src={FondoEmpresa} alt="description of image"/>
        <div className="estilo_contenedor">
          <div>
            <h1 className='registro'><b>Confirmación de registro</b></h1>
          </div>
          <br />
          <Create
            username={this.props.username}
          />
          <br />
          <br />
          <Button
            className="estiloCrearAceptar"
            size="lg"
            onClick={this.cerrarSesion}
          >
            <div style={{fontSize:'90%'}}>Cerrar sesión</div>
			    </Button>
        </div>
      </div>
    );
  }
}
export default Render;
