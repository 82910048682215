import * as iconosAi from "react-icons/ai";
import * as iconosBi from "react-icons/bi";
import * as iconosBs from "react-icons/bs";
import * as iconosFa from "react-icons/fa";
import * as iconosFc from "react-icons/fc";
import * as iconosFi from "react-icons/fi";
import * as iconosMd from "react-icons/md";
import * as iconosSi from "react-icons/si";
import * as iconosGr from "react-icons/gr";
import * as iconosVsc from "react-icons/vsc";
import * as iconosTb from "react-icons/tb";


var colorFondo = ""
var colorTexto = ""
var link = ""

console.log(localStorage.getItem("modoNocturno"))
if(localStorage.getItem("modoNocturno") === "activo"){
    colorFondo = "#333333";
    colorTexto = "#FFFFFF";
    link = "linkB"
}
else{
    colorFondo = "#FFFFFF"
    colorTexto = "#000000"
    link = "linkN"
}


var colorPrincipal = '#7c09c8';
var colorSecundario = '#9900ff'
var colorTextoPrincipal = '#FDFEFE';
var colorTextoSecundario = '#FDFEFE';
var colorGondi="#7c09c8"

/*BOTONES*/
var colorBotonCrearAceptar = '#F0F8FF';
var colorBotonEditar = '#F0F8FF';
var colorBotonEliminar = '#F0F8FF';
var colorBotonAccion = '#F0F8FF';
var colorBotonFiltros ='#F0F8FF';

var colorTextoCrearAceptar = '#7c09c8';
var colorTextoEditar = '#7c09c8';
var colorTextoEliminar = '#7c09c8';
var colorTextoAccion = '#7c09c8';
var colorTextoFiltros ='#7c09c8';

var tamanoFuenteBoton = 15;
var tamanoFuenteEncabezado = 15;
var tamanoFuenteTexto = 15;


var colorIconos = '#3B83BD';
var fuenteIconos = 20;
/* TABLAS */
var colorTablaEncabezado = '#7c09c808';
var colorTextoTablaEncabezado = '#7c09c8';


/* MODO NOCTURNO */
var colorFondoMN = '#FFFFFF';//CCCCCC
var colorTextoMN = 'black';
var colorInput ='#FFFFFF';
var colorFondoCard = '';
var colorTextoCard = '';


/* ICONOS GENERALES */
var IconoSol = iconosFa['FaSun']
var IconoLuna = iconosFa['FaMoon']
var IconoRFID = iconosAi['AiOutlineTags']
var IconoRastreo = iconosMd['MdGpsFixed'];
var IconoLectura = iconosFc['FcReading'];
var IconoPermisos = iconosFc['FcHighPriority'];
var IconoFiltrosAc = iconosFc['FcFilledFilter'];
var IconoFiltrosDes = iconosFc['FcEmptyFilter'];
var IconoLimpiarFiltros = iconosFc['FcClearFilters'];
var IconoExcel = iconosFc['FcDataSheet'];
var IconoDescarga = iconosFc['FcDownload'];
var IconoMail = iconosAi['AiOutlineMail'];
var IconoRegistrar = iconosFc['FcPlus'];
var IconoActualizar = iconosFc['FcTodoList'];
var IconoContrasena = iconosFc['FcKey'];
var IconoEliminar = iconosFc['FcFullTrash'];
var IconoAceptar = iconosFc['FcCheckmark'];
var IconoCancelar = iconosFc['FcCancel'];
var IconoGuardar = iconosFc['FcOk'];
var IconoRegresar = iconosFc['FcUpLeft'];
var IconoSubir = iconosFc['FcUpload'];
var IconoDetalle = iconosFc['FcNeutralTrading'];
var IconoUsuarios = iconosFi['FiUsers'];
var IconoSucursal = iconosFc['FcShop'];
var IconoPermisosUsuarios = iconosFc['FcPrivacy'];
var IconoAyuda = iconosBi['BiHelpCircle']
var IconoSubirScroll = iconosMd['MdOutlineKeyboardArrowUp']
var IconoContraer = iconosMd['MdOutlineExpandLess']
var IconoExpandir = iconosMd['MdOutlineExpandMore']
var IconoCampana = iconosVsc['VscBell']
var IconoNotificacion = iconosVsc['VscBellDot']

/* ICONOS RFID */
var IconoCatalogos = iconosMd['MdLibraryBooks'];
var IconoProductoRFID = iconosFc['FcElectronics'];
var IconoMontacarguista = iconosFc['FcCollaboration'];
var IconoMontacargas = iconosFc['FcInTransit'];
var IconoAntena = iconosFc['FcWorkflow'];
var IconoReader = iconosFc['FcNfcSign'];
var IconoTrazabilidad = iconosSi['SiAlwaysdata']
var IconoConstrucion = iconosFa['FaTools']
var IconoReporte = iconosTb['TbReportAnalytics']
var IconoEnAlmacen = iconosFc['FcHeatMap'];
var IconoFueraAlmacen = iconosFc['FcExport'];
var IconoInventario = iconosFc['FcTodoList'];
var IconoUbicacion = iconosFc['FcCollect'];
var IconoComparativa = iconosVsc['VscGraph']
var IconoGraficaComp = iconosVsc['VscGraphLine']
var IconoBobina = iconosFa['FaToiletPaper'];



/* ICONOS SOLUCION */
var IconoHome = iconosAi['AiFillHome']
var IconoPerfil = iconosFa['FaUserAstronaut']
var IconoDerecha = iconosAi['AiOutlineArrowRight']
var IconoAbajo = iconosMd['MdKeyboardArrowDown']
var IconoLimpiar = iconosAi['AiOutlineClear']
var IconoNocturno = iconosBs['BsFillMoonStarsFill']
var IconoAgregarUsuario = iconosBs['BsFillPersonPlusFill']
var IconoDia = iconosBs['BsFillSunFill']
var IconoTelefono = iconosAi['AiFillPhone']
var IconoDescargaArchivo = iconosFa['FaFileDownload']
var IconoPersonaRegistro = iconosFc['FcBusinessman']
var IconoEditarImagen = iconosFc['FcEditImage']
var IconoIzquierda = iconosFc['FcLeft']
var IconoLista = iconosFc['FcNeutralTrading'];
var IconoMenu = iconosFc['FcMenu']
var IconoEmpresa = iconosFc['FcOrganization']
var IconoReglas = iconosFc['FcRules']
var IconoConfiguracion = iconosFc['FcSettings']
var IconoSalir = iconosMd['MdExitToApp']
var IconoSonrisa = iconosSi['SiIconify']
var IconoTodoLista = iconosFc['FcTodoList']
var IconoPagina = iconosFc['FcDocument']
var IconoSel=iconosFi['FiCheckSquare']
var IconoExport = iconosFc['FcDataSheet']
var IconoExportarExcel = iconosSi['SiMicrosoftexcel']
var IconoPDF = iconosFa['FaRegFilePdf']
var IconoMarcadorMapa = iconosFa['FaMapMarkerAlt'];
var IconoTodasLasUbicaciones = iconosFc['FcOrgUnit'];
var IconoDispositivos = iconosFc['FcMultipleSmartphones'];
var IconoHistorial = iconosFc['FcClock'];
var IconoUltimosRegistros = iconosFc['FcOvertime'];
var IconoPregunta = iconosFa['FaQuestion'];
var IconoFoco = iconosBs['BsLightbulbFill'];
// var IconoVersion = iconosGi['GiBookshelf'];
var IconoMV = iconosFa['FaDesktop'];
var IconoActualizarMV = iconosGr['GrUpdate'];
var IconoPowerMV = iconosAi['AiOutlinePoweroff'];
var IconoGuardarMV = iconosAi['AiOutlineSave'];
var IconoMonitoreoMV = iconosBs['BsClockHistory'];
var IconoConsolaMV = iconosBs['BsWindowDesktop'];


var IconoLlave = iconosFa['FaKey']
var IconoItem = iconosMd['MdSettings']



export default class Style{
}


export { colorInput, colorPrincipal, colorTextoPrincipal, colorTextoSecundario, colorBotonCrearAceptar, colorBotonEditar, colorBotonEliminar, colorTextoCrearAceptar,colorTablaEncabezado,colorTextoTablaEncabezado,colorSecundario };
export { colorGondi,colorTextoEditar, colorTextoEliminar, colorBotonAccion, colorBotonFiltros, colorTextoAccion, colorTextoFiltros, tamanoFuenteBoton, tamanoFuenteEncabezado, tamanoFuenteTexto };
export { IconoSol, IconoLuna, IconoRFID, IconoRastreo }
export { colorFondo, colorTexto, link, colorIconos, fuenteIconos };
export { IconoLlave, IconoItem }
export { colorFondoMN, colorTextoMN, colorFondoCard, colorTextoCard };
export { IconoLectura, IconoPermisos, IconoFiltrosAc, IconoFiltrosDes, IconoLimpiarFiltros, IconoExcel, IconoDescarga, IconoMail }
export { IconoRegistrar, IconoActualizar, IconoContrasena, IconoEliminar, IconoAceptar, IconoCancelar, IconoGuardar, IconoRegresar }
export { IconoSubir, IconoDetalle, IconoUsuarios, IconoSucursal, IconoPermisosUsuarios, IconoAyuda, IconoSubirScroll, IconoContraer, IconoExpandir }
export { IconoReporte, IconoMontacarguista, IconoProductoRFID, IconoReader, IconoAntena, IconoMontacargas, IconoEnAlmacen, IconoFueraAlmacen, IconoInventario, IconoTrazabilidad, IconoConstrucion }
export { IconoUbicacion, IconoComparativa, IconoGraficaComp, IconoBobina, IconoHome, IconoPerfil, IconoDerecha, IconoAbajo, IconoLimpiar, IconoNocturno, IconoAgregarUsuario }
export { IconoDia, IconoTelefono, IconoDescargaArchivo, IconoPersonaRegistro, IconoEditarImagen, IconoIzquierda, IconoLista, IconoMenu }
export { IconoCatalogos, IconoEmpresa, IconoReglas, IconoConfiguracion, IconoSalir, IconoSonrisa, IconoTodoLista, IconoPagina, IconoSel }
export { IconoExport, IconoExportarExcel, IconoPDF, IconoMarcadorMapa, IconoTodasLasUbicaciones, IconoDispositivos, IconoHistorial, IconoUltimosRegistros }
export { IconoPregunta, IconoFoco, IconoMV, IconoActualizarMV, IconoPowerMV, IconoGuardarMV, IconoMonitoreoMV, IconoConsolaMV }
export { IconoCampana, IconoNotificacion }