import React, { Component } from 'react'
import FondoEmpresa from "../../../media/images/Inicio.jpg";
import DjangoIn from './DjangoIn';


class Render extends Component {
  render(){
    return(
      <div className="estilo_base">
        <img className="estilo_imagen" src={FondoEmpresa} alt=""/>
        <div className="estilo_contenedor">
          <h1 className="estilo_titulo">
          </h1>
          <h5 className="estilo_subtitulo">
          </h5> 
          <DjangoIn
            iniciarSesion = {this.props.iniciarSesion}
          />
          <br />
        </div>
      </div>
    );
  }
}
export default Render;
