export const URL = 'http://xicoatl.com:8000';
//export const URL = 'http://216.238.67.83:8000';
//export const URLG = 'https://api.xicoatl.mx:8080/guacamole/#/';
//export const URL = 'https://api.xicoatl.mx';
export const URLG = 'https://api.xicoatl.mx:8080/guacamoles/';

//AUTORIZACIÓN
export const autorizacion =  { headers: { 'Authorization': 'Basic VjFydHU0bFcxejRyZDpSRFJ5YTFjeGVqUnlaQT09' } }
export const usuarioActualExterno= `${URL}/usuarios/usuarioActualExterno/`;
export const obtenerPermisosUsuario= `${URL}/usuarios/obtenerPermisosUsuario/`;
export const UploadProfilePictureFilter = `${URL}/usuarios/UploadProfilePictureFilter/`;


export const tokenAuth= `${URL}/usuarios/tokenAuth/`;
export const generarAutorizacion= `${URL}/usuarios/generarAutorizacion/`;
export const usuarioActual= `${URL}/usuarios/usuarioActual/`;


export const codificarPassword= `${URL}/usuarios/codificarPassword/`;
export const media= `${URL}/media/reportes/`;
export const enviarReporte= `${URL}/usuarios/enviarReporte/`;
export const enviarReportesVM = `${URL}/vcenter/enviarReportes/`;
export const enviarMensajeBienvenida= `${URL}/usuarios/enviarMensajeBienvenida/`;
export const enviarMensajePassword= `${URL}/usuarios/enviarMensajePassword/`;
export const maquinasCliente= `${URL}/usuarios/maquinasCliente/`;

export const UsuarioBackList= `${URL}/usuarios/UsuarioBackList/`;
export const MenuList= `${URL}/usuarios/MenuList/`;
export const CompanyList= `${URL}/usuarios/CompanyList/`;
export const BranchList= `${URL}/usuarios/BranchList/`;
export const UsuariosExternosList = `${URL}/usuarios/UsuariosExternosList/`;
export const PermisoUsuarioList = `${URL}/usuarios/PermisoUsuarioList/`;
export const UsuariosExternosModify = `${URL}/usuarios/UsuariosExternosModify/`;
export const UsuariosExternosFilter = `${URL}/usuarios/UsuariosExternosFilter/`;



//
export const CaracteristicasSoporteList = `${URL}/inventario/CaracteristicasSoporteList/`;
export const CaracteristicasSoporteModify = `${URL}/inventario/CaracteristicasSoporteModify/`;
export const CaracteristicasSoporteFilter = `${URL}/inventario/CaracteristicasSoporteFilter/`;
//
export const HardwareInventoryList = `${URL}/inventario/HardwareInventoryList/`;
export const HardwareInventoryModify = `${URL}/inventario/HardwareInventoryModify/`;
export const HardwareInventoryFilter = `${URL}/inventario/HardwareInventoryFilter/`;
//
export const EquipoList = `${URL}/inventario/EquipoList/`;
export const EquipoModify = `${URL}/inventario/EquipoModify/`;
export const EquipoFilter = `${URL}/inventario/EquipoFilter/`;
//
export const SoporteSWList = `${URL}/inventario/SoporteSWList/`;
export const SoporteSWModify = `${URL}/inventario/SoporteSWModify/`;
export const SoporteSWFilter = `${URL}/inventario/SoporteSWFilter/`;
//
export const MVClientesList = `${URL}/vcenter/MVClientesList/`;
export const MVClientesModify = `${URL}/vcenter/MVClientesModify/`;
export const MVClientesFilter = `${URL}/vcenter/MVClientesFilter/`;
//
export const deleteHwSg = `${URL}/Inventario/deleteHwSg/`;
export const addSH = `${URL}/Inventario/addSH/`;

export const SoporteHWList = `${URL}/inventario/SoporteHWList/`;
export const SoporteHWModify = `${URL}/inventario/SoporteHWModify/`;
export const SoporteHWFilter = `${URL}/inventario/SoporteHWFilter/`;

export const VlanList = `${URL}/redes/VlanList/`;
export const VlanModify = `${URL}/redes/VlanModify/`;
export const VlanFilter = `${URL}/redes/VlanFilter/`;

// para veeam
export const BackupList = `${URL}/backups/BackupList/`;
export const BackupModify = `${URL}/backups/BackupModify/`;
export const BackupFilter = `${URL}/backups/BackupFilter/`;


export const OficinaList = `${URL}/inventario/OficinaList/`;
export const OficinaModify = `${URL}/inventario/OficinaModify/`;
export const OficinaFilter = `${URL}/inventario/OficinaFilter/`;

export const UsuariosInternosFilter = `${URL}/usuarios/UsuariosInternosFilter/`;
export const UsuariosInternosList = `${URL}/usuarios/UsuariosInternosList/`;
export const UsuarioBackFilter = `${URL}/usuarios/UsuarioBackFilter/`;
export const UsuarioBackModify = `${URL}/usuarios/UsuarioBackModify/`;
export const UsuariosInternosModify = `${URL}/usuarios/UsuariosInternosModify/`;

export const generarToken = `${URL}/usuarios/generarToken/`;
export const enviarToken = `${URL}/usuarios/enviarToken/`;

export const informacionPermisos = `${URL}/usuarios/informacionPermisos/`;
export const informacionPermisosTodos = `${URL}/usuarios/informacionPermisosTodos/`;

export const LogUsuarioList = `${URL}/usuarios/LogUsuarioList/`;
export const LogUsuarioModify = `${URL}/usuarios/LogUsuarioModify/`;
export const LogUsuarioFilter = `${URL}/usuarios/LogUsuarioFilter/`;

export const UploadProfilePictureList = `${URL}/usuarios/UploadProfilePictureList/`;
export const UploadProfilePictureModify = `${URL}/usuarios/UploadProfilePictureModify/`;


//PROYECTO EN CUESTION
//LIST
export const ForkliftUserList = `${URL}/forklift/ForkliftUserList/`;
export const UploadFileFUList = `${URL}/forklift/UploadFileFUList/`;
export const RFIDProductList = `${URL}/rfid/RFIDProductList/`;
export const UploadFileRPList = `${URL}/rfid/UploadFileRPList/`;
export const MapRFIDList = `${URL}/rfid/MapRFIDList/`;
export const ForkliftReaderList = `${URL}/forklift/ForkliftReaderList/`;
export const ForkliftsList = `${URL}/forklift/ForkliftsList/`;
export const UploadFileReporteList = `${URL}/forklift/UploadFileReporteList/`;

//MODIFY
export const ForkliftUserModify = `${URL}/forklift/ForkliftUserModify/`;
export const UploadFileFUModify = `${URL}/forklift/UploadFileFUModify/`;
export const RFIDProductModify = `${URL}/rfid/RFIDProductModify/`;
export const UploadFileRPModify = `${URL}/rfid/UploadFileRPModify/`;
export const MapRFIDModify = `${URL}/rfid/MapRFIDModify/`;
export const ForkliftReaderModify = `${URL}/forklift/ForkliftReaderModify/`;
export const ForkliftsModify = `${URL}/forklift/ForkliftsModify/`;
export const UploadFileReporteModify = `${URL}/forklift/UploadFileReporteModify/`;

//FILTER
export const ForkliftUserFilter = `${URL}/forklift/ForkliftUserFilter/`;
export const UploadFileFUFilter = `${URL}/forklift/UploadFileFUFilter/`;
export const RFIDProductFilter = `${URL}/forklift/RFIDProductFilter/`;
export const MapRFIDFilter = `${URL}/rfid/MapRFIDFilter/`;
export const ForkliftReaderFilter = `${URL}/forklift/ForkliftReaderFilter/`;
export const ForkliftsFilter = `${URL}/forklift/ForkliftsFilter/`;
export const UploadFileReporteFilter = `${URL}/forklift/UploadFileReporteFilter/`;

//REPORTES
export const UbicacionProductoRFID = `${URL}/forklift/ubicacionProductoRFID/`;
export const Trazabilidad = `${URL}/forklift/detalleTag/`;
export const guardarDatosExcel = `${URL}/forklift/guardarDatosExcel/`;

export const ForkliftRouteList = `${URL}/forklift/ForkliftRouteList/`;
export const ForkliftRouteModify = `${URL}/forklift/ForkliftRouteModify/`;
export const ForkliftRouteFilter = `${URL}/forklift/ForkliftRouteFilter/`;

//RASTREO
//LIST
export const CatalogoDeDispositivosList = `${URL}/rastreo/CatalogoDeDispositivosList/`;
export const UbicacionDispositivoList = `${URL}/rastreo/UbicacionDispositivoList/`;
export const VehiculoList = `${URL}/rastreo/VehiculoList/`;
export const CatalogoDeMoldesList = `${URL}/rastreo/CatalogoDeMoldesList/`;
export const ReporteDeMoldesList = `${URL}/rastreo/ReporteDeMoldesList/`;

//MODIFY
export const CatalogoDeDispositivosModify = `${URL}/rastreo/CatalogoDeDispositivosModify/`;
export const UbicacionDispositivoModify = `${URL}/rastreo/UbicacionDispositivoModify/`;
export const VehiculoModify = `${URL}/rastreo/VehiculoModify/`;
export const CatalogoDeMoldesModify = `${URL}/rastreo/CatalogoDeMoldesModify/`;
export const ReporteDeMoldesModify = `${URL}/rastreo/ReporteDeMoldesModify/`;

//FILTER
export const CatalogoDeDispositivosFilter = `${URL}/rastreo/CatalogoDeDispositivosFilter/`;
export const UbicacionDispositivoFilter = `${URL}/rastreo/UbicacionDispositivoFilter/`;
export const VehiculoFilter = `${URL}/rastreo/VehiculoFilter/`;

export const CatalogoDeMoldesFilter = `${URL}/rastreo/CatalogoDeMoldesFilter/`;
export const ReporteDeMoldesFilter = `${URL}/rastreo/ReporteDeMoldesFilter/`;

