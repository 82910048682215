import React , { Component }from "react";
import axios from "axios";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Button } from "reactstrap";
import * as back from "../../../../constants/urls";
import 'bootstrap/dist/css/bootstrap.css';
import { IconoGuardar } from "../../Style";

require('../../../../../node_modules/react-combo-select/style.css');

class Format extends Component{	
	
	constructor(props){		
		super(props);
			this.state = {
			}
	}

	componentDidMount(){
	}

	register = e => {
		e.preventDefault();
		axios.post(back.UsuariosExternosList,{
			"username": this.props.username,
		},
		back.autorizacion).then( () => {		
			axios.post(back.enviarMensajeBienvenida+this.props.username +'/', back.autorizacion).then( () => {
				axios.post(back.PermisoUsuarioList,
					{
						"username": this.props.username,
						"menuP": "/Home",
						"readP": true,
						"createP": false,
						"updateP": false,
						"deleteP": false,
						"exportP": false,
						"userUpdating": "servidor",
					}
					, back.autorizacion).then( () => {
						window.location.reload();
				}
				).catch(error => {console.log("E-Menu",error);});
				// axios.post(back.PermisoUsuarioList,
				// 	{
				// 		"username": this.props.username,
				// 		"menuP": "/RFID/Usuario",
				// 		"readP": true,
				// 		"createP": false,
				// 		"updateP": false,
				// 		"deleteP": false,
				// 		"exportP": false,
				// 		"userUpdating": "servidor",
				// 	}
				// 	, back.autorizacion).then( () => {
				// 	window.location.reload();
				// }
				// ).catch(error => {console.log("E-Menu",error);});
			}
			).catch(error => {console.log("E-Mensaje",error);});
		}
		).catch(error => {
			console.log("E-FrontUE",error); 
		});
		
	};

	defaultIfEmpty = value => {
		return value === "" ? "" : value;
	};
  
	render() {
		return (
			<Form onSubmit={this.register}>
				<FormGroup>
					<Label for="email">¿Este es su correo electrónico?</Label>
					<Input
						type="text"
						name="email"
						// onChange={this.onChangeCorreo}
						value={this.defaultIfEmpty(this.props.username)}
						disabled={true}
					/>
				</FormGroup>
				
				<div align="right">
				<Button           			
					className="estiloAccion"
					size="lg"
					type="submit"
				>
					<div style={{fontSize:'90%'}}><IconoGuardar />Acepto</div>
				</Button>
				</div>
			</Form>
		);
	}
}
export default Format;