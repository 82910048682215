import React, { Component, Fragment } from "react";
import {Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "reactstrap";
import Format from "./Format";
import { IconoPersonaRegistro } from "../../Style";

class Create extends Component{
	state = {
		modal: false
	};
	
	toggle = () => {
		this.setState(previous => ( {
			modal: !previous.modal
		} ) );
	};

	render() {
        var title = "Confirmación de registro";
		
		return (
			<Fragment>
				<Button
					className="estiloCrearAceptar"
					size="lg"
                    onClick={this.toggle}			
			    >
					<div style={{fontSize:'90%'}}><IconoPersonaRegistro /> Validar datos</div>
			    </Button>

				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>{title}</ModalHeader>
					<ModalBody>
						<Format
							toggle={this.toggle}
							username={this.props.username}
						/>
					</ModalBody>
				</Modal>
			</Fragment>
		);
	}
};
export default Create;